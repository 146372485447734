@import "../../styles/variables";

.prompt {
  background: $card-dark-bg;
  border-radius: $modal-border-radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 20px 65px 40px;
  max-width: 1300px;
  margin: 0 auto;

  &__title {
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    color: $main_dark-scheme;
    font-weight: 800;
    font-size: 1.5rem;

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30%;

      &--right {
        width: 25%;
      }
    }

    .card--prompt {
      background: #FFF0EB !important;
      max-width: 200px;
      margin: 0 30px 0 10px;

      * {
        color: black !important;
      }

      .card__row-info {
        /*width: 35px;*/
      }
    }
  }

  &__info {
    position: relative;

    &--code {
      padding-top: 20px;
    }

    &--name {
      padding: 75px 0 35px;
    }

    &--score {
      width: 90%;
      padding-top: 20px;
    }

    &--short-name {
      margin-bottom: 85px;
    }

    &--year {
      padding: 20px 0;
    }

    &-label {
      &--name {
        position: absolute;
        bottom: 0;
      }

      &--score {
        position: relative;
        top: 20px;
      }
    }

    .prompt__arrow {
      position: absolute;
      z-index: 1;

      &--code {
        right: -15px;
        bottom: 50%;
      }

      &--name {
        right: 10px;
        bottom: 20px;
      }

      &--score {
        right: -20%;
      }

      &--short-name {
        bottom: 85%;
        left: -15%;
      }

      &--year {
        left: -10%;
        top: 90%;
      }
    }
  }

  &__button {
    background: $main_dark-scheme;
    color: white;
    border: 2px solid $main_dark-scheme;

    &:focus,
    &:hover {
      background: $card-dark-bg;
      border: 2px solid $main_dark-scheme;
    }
  }

  &--light {
    background: $prompt-light;

    .prompt__content-info {
      color: black;
    }

    .prompt__content .card--prompt {
      background: white !important;
      border: 2px solid $main-light-scheme;
    }

    .prompt__button {
      background: $main_light-scheme;
      color: white;
      border: 1px solid $main_light-scheme;

      &:focus,
      &:hover {
        background: white;
        color: $main_light-scheme;
        border: 2px solid $main_light-scheme;
      }
    }
  }
}

@media (max-width: 1500px) {
  .prompt__content {
    font-size: 1.3rem;
  }

  .prompt__info .prompt__arrow--code {
    right: -35px;
  }
}

@media (max-width: 1280px) {
  .prompt__content {
    .card--prompt {
      margin: 0 30px;
    }

    &-info {
      .prompt__arrow--code {
        right: -55px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .prompt__content {
    font-size: 1rem;
  }

  .prompt__info .prompt__arrow {
    width: 75%;
  }
}

@media (max-width: 820px) {
  .prompt {
    .card--prompt {
      max-width: 175px;
    }
  }
}

@media (max-width: 755px) {
  .prompt {
    .card--prompt {
      max-width: 150px;
      margin: 0 15px;
    }
  }

  .prompt__info {
    .prompt__arrow {
      &--name {
        bottom: 35px;
        right: 0;
      }

      &--score {
        right: -50%;
        bottom: -20px;
      }

      &--year,
      &--short-name {
        left: -25%;
      }

    }
  }
}

@media (max-width: 700px) {
  .prompt__content {
    font-size: 13px;
  }

  .prompt__info {
    .prompt__arrow {
      display: none;
    }
  }
}

@media (max-width: 540px) {
  .prompt {
    padding-top: 30px;
    padding-bottom: 30px;

    &__title {
      font-size: 1.2rem;
    }

    &__button {
      margin-top: 10px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .prompt__content {
    font-size: 11px;
  }
  .prompt__info {

    &--code {
      top: -30px;
      left: 100%;
    }

    &--name {
      bottom: 10px;
      left: 160px;
    }

    &--score {
      width: 200px;
      top: 5px;
      left: 50%;
    }

    &--short-name {
      bottom: -35px;
      right: 100%;
    }

    &--year {
      bottom: -50px;
      right: 60px;
    }
  }
}

