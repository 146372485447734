@import "../../styles/variables";

.contacts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $contacts-padding;

  &__description {
    display: flex;
    align-items: center;
    border-radius: $modal-border-radius;
    box-shadow: 6px 0 40px rgba(0, 0, 0, 0.25);
    padding: 0 $contacts-article-padding;
    height: $contacts-height;
    width: 35%;

    &-title {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &-info {
      line-height: 27px;
      text-align: justify;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: bold;
    }

    &-telegram {
      color: white;
      cursor: pointer;
      text-decoration: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      font-family: $input-font;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--light {
    background: $card-light-bg;

    .contacts__description {
      background: $main_light-scheme;
    }
  }

  &--dark {
    background: #262626;

    .contacts__description {
      background: $main_dark-scheme;
    }
  }
}
.contacts-sent {
  font-size: 1.75rem;
}

.form-contacts {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  width: 40%;

  &__button {
    background: white;

    &:hover,
    &:focus {
      background: #165C8E;
      color: white;
    }

    &:disabled {
      background: #53626b;
      cursor: auto;
    }
  }
}

@media (max-width: 1600px) {
  .contacts {
    padding-left: $contacts-article-padding;
    padding-right: $contacts-article-padding;
  }
}

@media (max-width: 1240px) {
  .contacts__description {
    padding: 0 $padding-container1000;
    height: 500px;
  }
}

@media (max-width: 1000px) {
  .contacts {
    padding: 0;

  }

  .contacts__description-title {
    font-size: 22px;
  }
}

@media (max-width: 750px) {
  .contacts {
    flex-direction: column;
    align-items: start;
    padding-bottom: 40px;
    margin-left: 0;
    margin-right: 0;

    &--light {
      .contacts__description {
        background: transparent;
        color: black;

        &-telegram {
          color: black;
        }
      }
    }

    &--dark {
      background: $dark-bg;
      .contacts__description {
        background: transparent;
      }
    }

    &__description {
      box-shadow: none;
      padding: 60px 20px 40px;
      width: 90%;
      height: auto;

      &-info {
        font-size: 16px;
      }
    }
  }

  .form-contacts {
    margin-left: 20px;
    width: 90%;

    .Button {
      margin: 0 auto;
    }
  }

  .contact-element {
    margin-bottom: 20px;
  }

  .error-tooltip__description {
    top: 50px;
  }
}


@media (max-width: 425px) {
  .form-contacts {
    margin-left: 0;
    width: 100%;
  }

  .contacts__description {
    padding: 60px 10px 40px 0;
    width: 100%;
  }
}
