@import "variables";

.App {
  min-height: 100vh;
  color: #fff;
  width: 100%;
  height: 2055px;
  font-family: $main-font;

  &__main {
    &--light {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.633646) 27.75%, #FFFFFF 39%),url("../assets/images/light.jpg") center 0 no-repeat #ffffff;

      .App__contacts {
        margin-top: 45px;
        background: $card-light-bg;
        border-radius: 22px 22px 0 0;
      }
      @media (max-width: 750px){
        .App__contacts {
          background: white;
          border-radius: 0;
          //margin-top: -200px;
        }
      }
      .home-page{
        .data-form{
          .input-container{
            .input__value:focus{
              border: 3px solid $main_light-scheme;
            }
          }
          .select-container{
            .select__place:focus{
              border: 3px solid $main_light-scheme;
            }
          }
        }
      }
    }
    @media (max-width: 992px){
      &--light{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.633646) 32.75%, #FFFFFF 37%),url("../assets/images/light.jpg") center 0 no-repeat #ffffff;;
      }
    }

    &--dark {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(14, 14, 14, 1) 25.94%, #121212 34.29%),  url("../assets/images/dark.jpg") center 0 no-repeat  #121212;
      background-size: contain;
      .App__contacts {
        background: #262626;
        border-radius: 22px 22px 0 0;
      }
    }
    @media (max-width: 750px){
      &--dark{
        .App__contacts{
          background: $dark-bg;
        }
      }
    }
  }
}

.telegram {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 20%;
  right: 0;
  text-decoration: none;

  &__title {
    display: none;
    padding: 12px;
    font-size: 20px;
    color: black;
    font-weight: 700;
    background: white;
    border-radius: $border-radius * 2;
    margin-right: 12px;
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -9px;
      border-width: 9px;
      border-style: solid;
      border-color: transparent transparent transparent white;
    }
  }

  &__icon {
    background: white;
    display: flex;
    align-items: center;
    border-radius: 50% 0 0 50%;
    padding: 4px 8px 4px 4px;
    box-shadow: -3px 0 15px 0 rgba(145, 145, 145, 0.4);
  }

  &:hover > .telegram__title {
    display: block;
  }
}

@media (max-width: 1240px) {
  .telegram__icon {
    width: 70px;

    img {
      max-width: 90%;
    }
  }
}
@media (max-width: 1500px) {
  .App__main {
    &--dark {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(14, 14, 14, 1) 17.94%, #121212 21.29%),  url("../assets/images/dark.jpg") center 0 no-repeat  #121212;
      background-size: contain;
    }
  }
}
@media (max-width: 1050px) {
  .App__main {
    &--dark {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(14, 14, 14, 1) 17.94%, #121212 21.29%),  url("../assets/images/dark.jpg") center 0 no-repeat  #121212;
      background-size: contain;
    }
  }
}

@media (max-width: 992px) {
  .App__main {
    &--dark {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(14, 14, 14, 1) 15.94%, #121212 24.29%),  url("../assets/images/dark.jpg") center 0 no-repeat  #121212;
      background-size: contain;
    }
  }
}


@media (max-width: 750px) {
  .App__main {
    &--dark {
      background: $dark-bg;
    }
  }

  .App__contacts {
    margin-bottom: 60px !important;
  }

  .telegram__icon {
    width: 50px;
  }
}

@media (max-width: 540px) {
  .header__language-main span {
    font-size: 14px;
  }
  .page__title {
    font-size: 1.2rem;
    margin: 10px 0;
    line-height: normal;
  }
  .status-list__title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .status-list__item {
    font-size: 15px;
  }
  .data-form__row--title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .input__value {
    font-size: 15px;
  }
  .check__label {
    font-size: 15px;
  }
  .Button {
    font-size: 0.98rem;
    padding: 10px;
  }
  .results__title {
    font-size: 1.2rem !important;
    padding: 10px !important;
  }
  .sort {
    font-size: 12px;
  }
  .results__specialties-title {
    font-size: 1.1rem;
    text-transform: none;
  }
  .results__button {
    padding: 12px 34px;
    font-size: 0.85rem;
  }
  .steps__title {
    font-size: 1.2rem;
    padding-bottom: 10px;
    line-height: normal;
  }
}
