@import "../../styles/variables";

.sort {
  align-items: end;
  cursor: pointer;
  color: white;
  border-radius: $border-radius;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -54px;
  margin-top: 34px;
  margin-right: -52%;
  position: relative;

  &__label {
    font-size: 1.13rem;
    margin-right: 20px;
    font-weight: 500;
    justify-self: start;
    padding-left: 45px;
  }

  &__value {
    display: flex;
    align-items: center;
    background: transparent;
    text-align: right;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 10px;
      right: $input-padding;
      cursor: pointer;
      font-size: 1rem;
      background: url("../../assets/images/w-arrowDown.svg") center no-repeat;
      color: black;
      z-index: 100;
      background-size: cover;
    }
  }

  &__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 105%;
    right: 0;
    border-radius: $border-radius;

    &-option {
      font-size: 14px;
      font-family: $input-font;
      min-width: 240px;
      background: $dark-bg;
      z-index: 100;

      &:hover {
        background: $main_dark-scheme;
        border: 2px solid #696969;
      }

    }
  }
  &__drop-down {
    padding: 17px 34px;
    border-radius: $border-radius;
    min-width: 250px;
    border: 2px solid #ff6020;
  }
  &__menu-option{
    border-radius: 0px;
  }
  &--light {
    color: black;
    .sort__value {
      border: none;
      background: white;

      &::after {
        background: url("../../assets/images/arrowDown.svg") center no-repeat;
        background-size: cover;
      }
    }
    .sort__drop-down{
      border: 2px solid #2075F0;
    }

    .sort__menu {
      &-option {
        border-radius: 0px;
        background: white;
        &:hover {
          background: $main_light-scheme;
          color: #fff;
        }
      }
    }
  }


}
@media (max-width: 1500px) {
  .sort {
    margin: 0;
    align-self: flex-end;
    margin-bottom: 10px;
  }
}
@media (max-width: 1100px) {
  .sort {
    margin-bottom: 25px;
  }
}

@media (max-width: 540px) {
  .sort {
    margin-right: 5px;
    margin-left: 5px;
    &__drop-down {
      min-width: 250px;
      padding: 12px;
    }
  }
}
