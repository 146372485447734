@import "../../../styles/variables";

.contact-element {
  position: relative;
  margin-bottom: $contacts-margin-input;
  display: flex;
  flex-direction: column-reverse;

  &__label {
    text-transform: uppercase;
    color: #000000;
    font-size: 1rem;
    line-height: 19px;
    font-weight: 600;

  }

  &__input {
    border-bottom: 2px solid;
    color: #393939;
    padding-top: 10px;
    padding-bottom: 4px;
    background: transparent;
    font-size: 1rem;
    cursor: text;
    font-weight: 600;
    line-height: 19px;
    font-family: $main-font;

    &:focus{
      border-bottom: 2px solid;
      font-family: $main-font;
      font-weight: 600;
      line-height: 19px;
    }
    &:focus ~ label,
    :valid ~ label{
      opacity: 0.5;
    }

    &::placeholder {
      color: #7b919c;
      font-weight: 600;
      line-height: 19px;
      font-family: $main-font;
    }
  }
  &:hover,
  :focus{
    &__label{
      color: #2075F0;
    }
  }
}
