@import "../../styles/variables.scss";

.page {
  &__title {
    text-transform: uppercase;
    margin: 0 0 60px;
    text-align: center;
    line-height: 44px;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
  }
}

.home-page {
  margin-top: -50px !important;
  &__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: $margin-start-form;
  }
}

.data-form {
  color: #ffffff;
  width: 100%;
  max-width: 600px;
  margin-left: 10%;
  position: relative;

  &__error {
    border-radius: $border-radius;
    font-size: 0.9rem;
    background: $error_scheme;
    padding: 10px 15px;
    position: absolute;
    right: 0;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 80%;
      margin-top: -5px;
      border-width: 8px;
      border-style: solid;
      border-color:  $error_scheme $error_scheme transparent transparent ;
    }
  }

  &__hidden {
    display: none;
  }

  &__row {
    &--title {
      display: flex;
      justify-content: space-between;
      margin-bottom: $margin-form-title;
      align-items: center;
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
    }

    &--exam {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 $margin-bottom-row;
      margin-bottom: $margin-bottom-row;
      padding-bottom: 5px;
      border-bottom: 2px solid #ffffff;
    }

    &--extra {
      border-bottom: none;
    }
  }

  &__submit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-error {
      color: #b61202;
      font-size: 16px;
    }
  }

  &__select {
    margin: 6px 12px;

    &-elem {
      width: 100%;
      border-bottom: 1px solid #ccc;
      font-size: 14px;
      -webkit-appearance: none;
      padding: 10px 0;

      &:hover,
      &:focus {
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

.status-list {
  width: 75%;
  max-width: 300px;

  &__title {
    display: inline-block;
    font-size: 32px;
    line-height: 39px;
    font-weight: bold;
    margin-bottom: $margin-form-title;
  }

  &__item {
    display: block;
    margin-bottom: $margin-bottom-row;
    cursor: pointer;
    text-decoration: none;
    padding: $input-padding;
    border-radius: $border-radius;
    font-size: $form-size;
    font-weight: 600;
    color: #ffffff;
    outline: 0;

    &--active {
      background: #fff !important;
      color: #000000;
    }
  }
}

.mobile-status {
  margin-bottom: 25px;
  display: none;
  flex-direction: column;
  width: 100%;

  &__title {
    display: inline-block;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      background: url("../../assets/images/arrowDown.svg") center no-repeat;
      background-size: cover;
      width: 12px;
      height: 8px;
      right: 20px;
      cursor: pointer;
      pointer-events: none;
    }
  }

  &__select {
    width: 100%;
    font-weight: 500;
    border-radius: $border-radius;
    padding: 10px 20px;
    -webkit-appearance: none;
    font-size: 1rem;
  }
}

@media (max-width: 1080px) {
  .home-page {
    margin-top: 0 !important;
  }
}

@media (max-width: 1000px) {
  .data-form {
    margin-left: 30px;
  }
}

@media (max-width: 750px) {
  .page__title {
    margin: 30px 0;
    font-size: 1.5rem;
  }
  .data-form__row--exam {
    grid-template-columns: 1fr;
  }

  .data-form__row--title {
    font-size: 1.5rem;
  }

  .status-list {
    &__item {
      margin-bottom: $margin-bottom-row / 2;
    }

    &__title {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 540px) {
  .home-page__content {
   flex-direction: column;
    margin-bottom: 70px;
  }

  .data-form {
    margin-left: 0;

    &__row {
      &--title {
        margin-bottom: 10px;
      }
    }

    &__submit {
      justify-content: center;
    }

    &__error {
      top: -30px;
      width: 200px;
    }
  }

  .status-list {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .page__title {
    margin: 15px 0;
    font-size: 1.2rem;
    text-align: center;
  }
}