@import "../../../styles/variables";

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $margin-bottom-row;

  input{
    position: relative;
  }
}

.input {
  cursor: text;
  &__label {
    position: absolute;
    padding: 0 15px;
    font-weight: 600;
    left: 0;
    color: black !important;
    cursor: text;
  }

  &__value {
    color: black;
    padding: $input-padding - 3px;
    border-radius: $border-radius;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    border: 3px solid transparent;
    cursor: text;
    display: block;
    font-family: $input-font;

    &:focus {
      border: 3px solid $focus_input-scheme;

      &::placeholder{
        font-weight: 400;
        opacity: 0.5;
        text-align: right;
        color: $step-scheme;
      }
    }

    &::placeholder {
      color: $step-scheme;
      font-weight: 600;
      line-height: 19px;
      font-size: $form-size;
      font-family: $input-font;
    }
    &::-webkit-input-placeholder {color:$scroll;}
    &::-moz-placeholder          {color:$scroll;}/* Firefox 19+ */
    &:-moz-placeholder           {color:$scroll;}/* Firefox 18- */
    &:-ms-input-placeholder      {color:$scroll;}

    @-moz-document url-prefix()
    {
      &::placeholder {color:black; opacity: 0.7;}
    }

    &--error {
      border: 3px solid $error_scheme;
    }
    &:placeholder-shown + .input-label{
      visibility: hidden;
      z-index: -1;
    }
    &:not(:placeholder-shown) + .input-label,
    &:focus:not(:placeholder-shown) + .input-label{
      visibility: visible;
      z-index: 1;
      opacity: 0.5;
    }
  }
}
.input-label{
  display: block;
  position: absolute;
  right: 0.5rem;
  color: black;
  font-family: $input-font;
  opacity: 0;
  font-size: 0.83rem;
}
.search-select__label{
  font-family: $input-font;
}

@media (max-width: 840px) {
  .input {
    &__value {
      &:focus {
        &::placeholder{
          opacity: 0;
        }
      }
      &:placeholder-shown + .input-label{
        visibility: hidden;
        z-index: -1;
      }
      &:not(:placeholder-shown) + .input-label,
      &:focus:not(:placeholder-shown) + .input-label{
        visibility: hidden;
        z-index: -1;
        opacity: 0;
      }
    }
  }
  .data-form__row--exam {
    .input-container {
      .input {
        &__value {
          &:focus {
            &::placeholder{
              opacity: 0.5;
            }
          }
          &:placeholder-shown + .input-label{
            visibility: hidden;
            z-index: -1;
          }
          &:not(:placeholder-shown) + .input-label,
          &:focus:not(:placeholder-shown) + .input-label{
            visibility: visible;
            z-index: 1;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .input-container {
    margin-bottom: $margin-bottom-row / 2;
  }
  .input {
    &__value {
      &:focus {
        &::placeholder{
          opacity: 0;
        }
      }
      &:placeholder-shown + .input-label{
        visibility: hidden;
        z-index: -1;
      }
      &:not(:placeholder-shown) + .input-label,
      &:focus:not(:placeholder-shown) + .input-label{
        visibility: hidden;
        z-index: -1;
        opacity: 0;
      }
    }
  }
  .data-form__row--exam {
    .input-container {
      .input {
        &__value {
          &:focus {
            &::placeholder{
              opacity: 0.5;
            }
          }
          &:placeholder-shown + .input-label{
            visibility: hidden;
            z-index: -1;
          }
          &:not(:placeholder-shown) + .input-label,
          &:focus:not(:placeholder-shown) + .input-label{
            visibility: visible;
            z-index: 1;
            opacity: 0.5;
          }
        }
      }
    }
  }
}


