@import "../../../styles/variables";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: $result-card-height;
  padding: 10px 15px;
  border-radius: 13px;
  text-transform: uppercase;
  //box-shadow: 2px 4px 4px rgba(112, 212, 255, 0.3);
  border: 2px solid transparent;
  color: white;
  cursor: pointer;

  &:hover {
    background: $card-dark-bg !important;
    border: 2px solid #fff;
  }
  &:hover > .card__tooltip {
    display: inline-block;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    &--name {
      font-weight: bold;

      /*&:hover > .card__tooltip {
        display: inline-block;
      }*/
    }

    &-code {
      font-weight: bold;
    }

    &-info {
      position: relative;
      width: 50px;


      &:last-child {
        text-align: right;
      }

      &:hover > .card__tooltip {
        display: inline-block;
      }

      &--short {
        text-align: right;
        font-weight: bold;
      }
      &--code{
        font-size: 0.85rem;
        font-weight: bold;
      }
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    top: 28px;
    left: -15px;
    z-index: 50;
    text-transform: none;
    font-weight: 400;
    min-width: 170px;
    font-size: 0.8rem;
    border-radius: $border-radius;
    padding: 7px;
    color: black;
    text-align: left;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      left: 20%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
    }

    &--bottom {
      top: -65px;

      &::after {
        bottom: -8px;
      }
    }

    &--right {
      transform: translateX(-105px);

      &::after {
        left: 80%;
      }
    }

    &--bottom.card__tooltip--right {
      top: -35px;
    }
  }

  &--light {
    background: $main_light-scheme;

    &:hover {
      background: white !important;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: black;
    }

    .card__tooltip {
      background: $card-light-bg;

      &::after {
        border-color: transparent transparent $card-light-bg $card-light-bg;
      }

      &--right {
        &::after {
          border-color: transparent $card-light-bg $card-light-bg transparent;
        }
      }

      &--bottom {
        &::after {
          border-color: $card-light-bg transparent  transparent $card-light-bg
        }
      }
    }

    &.card--contract {
      background: $card-light-bg;

      &:hover {
        color: black;
      }
    }
  }

  &--dark {
    background: $main_dark-scheme;

    .card__tooltip {
      background: $main_dark-scheme;

      &::after {
        border-color: transparent transparent $main_dark-scheme $main_dark-scheme;
      }

      &--right {
        &::after {
          border-color: transparent $main_dark-scheme $main_dark-scheme transparent;
        }
      }

      &--bottom {
        &::after {
          border-color: $main_dark-scheme transparent  transparent $main_dark-scheme
        }
      }
    }
  }

  &--contract {
    color: #000000;
    background: #ffffff;

    &:hover {
      color: white;
      border: 2px solid #fff;
    }
  }
}

.card__row--name {
  display: block;

  .message {
    text-transform: none;
    font-size: smaller;
    opacity: 0;
  }
}

.specialties {
  .card:hover,
  .card:focus {
    .message {
      opacity: 1;
    }
  }
}

/*.card__tooltip {
  display: none;
  position: absolute;
  top: 28px;
  left: -15px;
  z-index: 50;
  text-transform: none;
  font-weight: 400;
  min-width: 170px;
  font-size: 0.8rem;
  border-radius: $border-radius;
  padding: 7px;
  color: black;
  text-align: left;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 20%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
  }

  &--bottom {
    top: -65px;

    &::after {
      bottom: -8px;
    }
  }

  &--right {
    transform: translateX(-105px);

    &::after {
      left: 80%;
    }
  }

  &--bottom.card__tooltip--right {
    top: -35px;
  }
}
.card-link:hover > .card__tooltip {
  display: inline-block;
}
.card-link {
  .card {
    .card__tooltip {
      position: relative !important;
    }
  }
}*/
@media (max-width: 540px) {
  .card {
    min-height: 150px;
    font-size: 0.9rem;
  }
}

@media (max-width: 425px) {
  .card {
    font-size: 0.8rem;
    padding: 6px;
    text-transform: none;

    &__tooltip {
      min-width: 120px;
      font-size: 12px;

      &--right {
        transform: translateX(-70px);
      }
    }
  }
}

@media (max-width: 360px) {
  .card {
    min-height: 140px;
  }
}
