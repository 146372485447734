$main-font: 'Gilroy', sans-serif;
$input-font: 'Roboto', sans-serif;

$main_dark-scheme: #ff6020;
$main_light-scheme: #2075F0;
$main_light-scheme-dark: #1b3cc2;
$error_scheme: #F9003C;
$focus_input-scheme: #FF8759;
$card-dark-bg: #202020;
$dark-bg: #121212;
$card-light-bg: #C6DDFF;
$line-border: #9E9E9E;
$step-scheme: #434343;
$scroll: #494949;
$prompt-light: #f4f4f4;
$form-size: 16px;

$max-container-size: 1680px;
$padding-container: 120px;
$padding-container1000: 30px;
$margin-start-form: 225px;

$width-submit: 236px;
$margin-bottom-row: 20px;
$input-padding: 10px;
$input-padding-20: 20px;
$font-size: 16px;
$border-radius: 7px;
$margin-form-title: 20px;

$modal-border-radius: 22px;
$contacts-padding: 130px;
$contacts-article-padding: 80px;
$contacts-height: 512px;
$contacts-margin-input: 50px;

$margin-results: 24px;
$button-results-padding: 17px;
$button-results-mobile: 10px;
$result-card-height: 175px;

$margin-details: 15px;
$border-radius-step: 20px;
