@import "../../styles/variables";

.detailed {
  text-align: justify;
  padding: 30px 35px;
  font-size: 18px;
  .detailed-description__name{
    font-weight: bold;
  }
  .program__name{
    font-weight: bold;
  }

  .detailed-programs {
    .detailed-programs__part-name {
      font-weight: bold;
    }

    .detailed-programs__program {
      .program__links {
        .program__links-url {
          font-weight: bold;
        }
      }
    }
  }

  &--light {
    background: #f5f7ff;
    color: black;

    .detailed-programs {
      .detailed-programs__part-name {
        opacity: 0.7;
      }
    }

    .detailed__name,
    .detailed-description__name {
      color: $main_light-scheme;
    }

    .program {
      &__name {
        background: $main_light-scheme;
        font-weight: bold;
      }
      &__links-icon {
        color: $main_light-scheme;
      }
      &__links-url{
        color: $main_light-scheme-dark;
        text-decoration: none;
      }
      &__description {
        opacity: 0.7;
      }
      &__favourites {
        color: $main_light-scheme;
        border: 2px solid $main_light-scheme;
        background: white;

        &:hover,
        &:focus {
          background: $main-light-scheme;
          color: white;
        }
      }
    }
  }

  &--dark {
    background: $card-dark-bg;
    color: white;

    .detailed__name,
    .detailed-description__name {
      color: $main_dark-scheme;
    }

    .program {
      &__name {
        background: $main_dark-scheme;
      }

      &__links-icon {
        color: $main_dark-scheme;
      }
      &__links-url{
        color: white;
        text-decoration: none;
      }
      &__favourites {
        color: white;
        border: 2px solid $main-dark-scheme;

        &:hover,
        &:focus {
          background: $main-dark-scheme;
        }
      }
    }
  }

  &__name {
    margin-bottom: $margin-details;
    font-size: 20px;
  }

  &-description {
    margin-bottom: $margin-details * 2;

    &-title {
      text-align: left;
    }
  }

  &-programs__part-name {
    margin-bottom: $margin-details;
  }
}

.program {
  display: flex;
  flex-direction: column;
  line-height: 27px;
  padding-bottom: 10px;

  &__name {
    padding: 10px 25px;
    font-weight: 500;
    margin-bottom: $margin-details;
    color: white;
  }

  &__links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 300;

    &-url {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &-icon {
      margin-right: 10px;
      font-size: 1.5rem;
    }
  }

  &__description {
    padding-bottom: 5px;
    font-weight: 600;
  }

  &__embed-video {
    margin: 25px 0;
    height: 250px;
    width: 100%;
  }

  &__favourites {
    position: relative;
    left: calc(100% - 150px);
    background: transparent;
    padding: $button-results-padding $button-results-padding * 2;
    border-radius: $border-radius;
    text-transform: uppercase;
    width: 150px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

.favourites {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;
  &--light {
    color: $main_light-scheme;
  }
  &--dark {
    color: $main_dark-scheme;
  }
}

@media (max-width: 540px) {
  .program {
    &__links {
      flex-direction: column;
      align-items: start;
    }

    &__favourites {
      padding: $button-results-mobile $button-results-mobile * 2;
    }
  }

  .detailed {
    padding: 30px 15px;
  }
}

@media (min-width: 780px) {
  .program {
    &__embed-video {
      height: 420px;
    }
  }
}