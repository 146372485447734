@import "../../styles/variables";
$width_lang: 55px;

.header {
  background: transparent;
  padding-top: 1%;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    margin: 2px 12px 0 0;

    img{
      height: 50px;
    }
  }

  &__language {
    position: relative;
    cursor: pointer;
    padding: 10px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    &:hover > &-list {
      visibility: visible;
    }

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: $width_lang;
      margin-bottom: 5px;

      img{
        width: 26px;
      }
      span {
        width: 21px;
        height: 19px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-list {
      visibility: hidden;
      position: absolute;
      left: -40%;
      top: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: $border-radius;
      padding: 10px 20px;
      color: white;

      &__item {
        padding: 0 0 2px 0;

        &:hover {
          text-decoration: underline;
        }
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        top: -15px;
        margin-left: 5px;
        border-width: 8px;
        border-style: solid;
      }

      &.list-dark {
        background: $main_dark-scheme;

        &::after {
          border-color: transparent $main_dark-scheme $main_dark-scheme transparent;
        }
      }

      &.list-light {
        background: $main_light-scheme;

        &::after {
          border-color: transparent $main_light-scheme $main_light-scheme transparent;
        }
      }
    }
  }
}
.btn-top {
  background: url(backtotop.png) no-repeat center center;
  border: 0;
  bottom: 20px;
  cursor: pointer;
  display: block;
  height: 70px;
  position: fixed;
  right: 20px;
  text-indent: -9999px;
  width: 70px;
  z-index: 300;
}
.btn-top-dark:hover,
.btn-top-dark:focus {
  background: url(backtotop-dark.png) no-repeat center center;
}
.btn-top-light:hover,
.btn-top-light:focus {
  background: url(backtotop-light.png) no-repeat center center;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .btn-top {
    background: url(backtotop2x.png) no-repeat center center;
    background-size: 70px 70px;
  }
}

@media (max-width: 720px) {
  .header {
    padding-bottom: 0;
  }

  .header__language-main {
    img {
      width: 30px !important;
    }
  }

  .header__logo {
    max-width: none;
  }
}

@media (max-width: 400px) {
  .header__logo {
    img {
      height: 40px;
      width: auto;
    }
  }

  .header__language-main {
    flex-direction: row-reverse;
    position: relative;

    img {
      display: none;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 0;
      width: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      right: 30px;
      cursor: pointer;
      pointer-events: none;
    }
  }
  .header__language-list {
    left: 0;
  }
  .btn-top {
    height: 40px;
    width: 40px;
  }
}
