@import "variables.scss";

body,
h1,
h2,
h3,
a,
ul {
  margin: 0;
  padding: 0;
  font-family: Gilroy, sans-serif;
  font-size: $font-size;
}

input,
button,
select,
input:focus,
button:focus,
select:focus {
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Roboto, sans-serif;
}

img {
  max-width: 100%;
  width: auto;
}

ul {
  list-style: none;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 $padding-container / 2;
}

.big-container {
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

@media (max-width: 1600px) {
  .big-container {
    padding-left: $padding-container / 2;
    padding-right: $padding-container / 2;
  }
}

@media (max-width: 1100px) {
  .big-container,
  .container {
    padding-left: $padding-container1000;
    padding-right: $padding-container1000;
  }
}

@media (max-width: 700px) {
  .big-container,
  .container {
    padding-left: $padding-container1000 / 2;
    padding-right: $padding-container1000 / 2;
  }
}

@import "main";


