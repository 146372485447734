@import "../../../styles/variables";

$check-size: 25px;

.check {
  &-container {
    margin-bottom: $margin-bottom-row;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__input {
    width: $check-size;
    height: $check-size;
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: 119;
  }

  &__label {
    padding: 0 $input-padding + 20px;
    position: relative;
    margin-left: 15px;
    line-height: 19px;
    font-size: $form-size;
    font-weight: 600;
    font-family: $input-font;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: -15px;
      height: $check-size;
      width: $check-size;
      background-color: white;
      border-radius: 5px;
    }

    &::after {
      content: "";
      display: inline-block;
      height: 8px;
      width: 18px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      position: absolute;
      top: 20%;
      left: -10px;
      transform: rotate(-45deg);
      z-index: 100;
      color: #000000;
    }
  }
}

.check__input + .check__label::after {
  content: none;
}

.check__input:checked + .check__label::after {
  content: "";
}
