@import "../../../styles/variables";

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
}

.modal {
  position: fixed;
  z-index: 100000;
  width: 80%;
  max-width: 1200px;
  left: 0;
  right: 0;
  margin: auto;
  top: 6.5%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  /*border-radius: 22px;

  .modal__body {
    //padding-right: 20px;
    border-radius: 22px;
    .contacts {
      border-radius: 22px;
    }
  }*/

  &--light {
    background: #f0f0f0;

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover {
      background: $main_light-scheme;
      border-radius: 4px;
    }

    .modal__close {
      color: #262626;
    }
  }

  &--dark {
    background: #262626;

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover {
      background: $main_dark-scheme;
      border-radius: 4px;
    }

    .modal__close {
      color: #f0f0f0;
    }
  }

  &--detailed {
    max-height: 600px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $scroll;
      border-radius: 4px;
    }

    .modal--detailed::-webkit-scrollbar-thumb {
      background: white;
    }
  }

  &__header {
    position: relative;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;

    &-font {
      &:hover {
        color: #FF8759;
      }
    }
  }
}

@media (max-width: 540px) {
  .modal {
    width: 95%;

    &__close {
      right: 0;
      top: 5px;
      font-size: 10px;
    }
  }
}
