@import "../../../styles/variables";

.Button {
  display: block;
  padding: 20px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  font-family: $main-font;
  width: $width-submit;
  border-radius: 12px;
  color: #fff;

  &--light {
    background: $main_light-scheme;
    border: 3px solid $main_light-scheme;

    &:focus,
    &:hover {
      background: #fff;
      border: 3px solid $main_light-scheme;
      color: $main_light-scheme;
    }
  }

  &--dark {
    background: $main_dark-scheme;
    border: 3px solid $main_dark-scheme;


    &:focus,
    &:hover {
      background: #fff;
      color: $main_dark-scheme;
      border: 3px solid $main_dark-scheme;
    }
  }
}
