@import "../../styles/variables";

.results {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: $margin-bottom-row;
    border-bottom: 2px solid #434343;
    width: 95%;
    padding: $margin-bottom-row;
    text-align: center;
  }
  &__filter-input{
    font-size: 1.13rem !important;
    font-weight: 500;
    font-family: $input-font;
    color: $scroll;
  }

  &--light {
    color: #000000;
  }

  &--dark {
    color: #ffffff;

    .results__specialties-button {
      border: 2px solid $main_dark-scheme;
      color: white;
      background: $card-dark-bg;

      &:hover {
        background: $main_dark-scheme;
      }
    }
  }

  &__specialties {
    width: 100%;
    margin-bottom: 100px;

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-results;
    }

    &-title {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: 800;
    }

    &-button {
      background: white;

      &:hover,
      &:focus {
        border: 2px solid white;
        background: $card-dark-bg;
        color: white;
      }
    }
  }

  &__filter {
    margin-bottom: $margin-bottom-row;
    padding: 12px 22px;
    display: flex;
    align-items: center;
    min-width: 800px;
    background: white;
    border-radius: $border-radius;
    color: black;

    &-input {
      margin: 0 25px;
      width: 100%;
      font-size: 14px;
      line-height: 16px;

      &::placeholder {
        color: black;
      }
    }
  }

  &__button {
    padding: $button-results-padding $button-results-padding * 2;
    border: 2px solid $main_light-scheme;
    border-radius: $border-radius;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;

    &:hover {
      background: $main_light-scheme;
      color: white;
    }
  }
}

.specialties {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: $margin-results;
  width: 100%;
}

@media (max-width: 920px) {
  .results__filter {
    min-width: 600px;
    max-width: 600px;
  }
}

@media (max-width: 720px) {
  .specialties {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
  }
}

@media (max-width: 690px) {
  .results__filter{
    min-width: 450px;
    &-input{
      font-size: 14px !important;
    }
  }
  .sort{
    max-width: 450px !important;
    min-width: 450px !important;

    &__value{
      //margin-left: 0 !important;
    }

    &__label{
      font-size: 14px !important;
    }
  }

}

@media (max-width: 540px) {
  .results {
    &__title {
      font-size: 22px;
      text-align: left;
      width: auto;
    }

    &__filter {
      min-width: 400px;

      &-input{
        font-size: 14px !important;
        color: black;
      }

      &-input::placeholder {
        opacity: 0;
      }
    }

    &__sort-select {
      width: 300px;
    }

    &__sort-label {
      margin-right: 10px;

      &::after {
        right: 10px;
        width: 12px;
      }
    }
  }

  .specialties {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 15px;
  }

  .results__specialties {
    margin-bottom: 30px;

    &-title {
      font-size: 22px;
      text-transform: none;
    }

    &-row {
      margin-bottom: 15px;
    }

    &-button {
      display: none;
    }
  }
  .sort{
    max-width: 400px !important;
    min-width: 400px !important;

    &__value{
      //margin-left: 0 !important;
    }

    &__label{
      font-size: 14px !important;
    }
  }
}

@media (max-width: 475px) {
  .results {
    &__sort {
      width: 100%;
      flex-direction: column;
      align-items: start;
      margin-bottom: 35px;

      &-select {
        padding: 5px;
        width: 100%;
      }

      &-label {
        margin-bottom: 10px;

        &::after {
          top: 40px;
        }
      }
    }
  }
  .results {
    &__filter {
      min-width: 300px;
    }
  }
  .sort{
    max-width: 340px !important;
    min-width: 340px !important;
  }

  .specialties {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }
}

@media (max-width: 365px) {
  .specialties {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-gap: 10px;
  }
  .sort{
    //max-width: 290px !important;
    min-width: 310px !important;
    .sort__value {
      font-size: 12px;
    }
  }
  .results__filter {
    min-width: 250px;
  }
}
