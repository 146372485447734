@import "../../styles/variables";

.steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__title {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 2px solid $line-border;
    margin-bottom: 60px;
    text-align: center;
    font-size: 36px;
    line-height: 44px;

  }

  .step {
    display: flex;
    width: 650px;
    margin: 40px 0;
    font-family: $input-font;
    font-size: 18px;
    line-height: 21px;

    &__label {
      display: flex;
      align-items: center;
      padding: 15px 30px;
      background: $step-scheme;
      border-top-left-radius: 20px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 20px;
      width: 70%;
      height: 90px;
      box-sizing: border-box;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 40px;
        width: 40px;
        right: -15px;
        z-index: 1;
        top: 75px;
        border-width: 15px 15px 0 0;
        border-color: #434343;
        border-style: solid;
        border-radius: 0 40px 0 0;
      }
    }

    &__box {
      background: $step-scheme;
      padding: 22px 28px;
      border-top-left-radius: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      position: relative;

      /*&:after {
        content: "";
        position: absolute;
        height: 90px;
        width: 36px;
        top: 0;
        left: -19px;
        background: #434343;
      }*/

      &-interior {
        background: $main_dark-scheme;
        padding: 10px 12px 45px;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        font-weight: 500;
      }

      &-index {
        margin-bottom: 20px;
        text-align: right;
        font-weight: bold;
      }
    }

    &--right {
      flex-direction: row-reverse;

      .step__label {
        border-top-left-radius: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 0;
        &:after {
          left: -15px;
          transform: rotate(270deg);
        }
      }
      .step__box {
        border-top-left-radius: 20px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        &:after {
          left: 150px;
        }

        &-index {
          text-align: left;
        }
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    background: linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0.15) 26.56%, rgba(0, 0, 0, 0.22) 44.06%, rgba(3, 3, 3, 0.355652) 69.17%, #121212 90%), url("../../assets/images/dark.jpg") center 0 no-repeat;
    background-size: contain;
    background-size: 100%;

    &--light {
      //background:white;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 36.56%, rgba(255, 255, 255, 0.22) 64.06%, rgba(255, 255, 255, 0.355652) 79.17%, #ffffff 100%), url("../../assets/images/light.jpg") center 0 no-repeat;

      .steps__title {
        color: black;
      }

      .step {
        &__label,
        &__box,
        &__box:after {
          background: $main_light-scheme;
        }

        &__label:after {
          border-color: $main_light-scheme;
        }

        &__box-interior {
          background: white;

          * {
            color: black;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .steps .step {
    width: 500px;
    margin: 40px 20px;

    &__label {
      font-size: 14px;
      padding-left: 40px;
    }

    &__box {
      &-interior {
        padding-bottom: 35px;
      }

      &-date {
        text-align: center;
      }
    }
  }

  .steps .step--right .step__box:after {
    left: 140px;
  }
}
@media (max-width: 1750px) {
  .steps {
    &-container{
      background: linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0.15) 26.56%, rgba(0, 0, 0, 0.22) 44.06%, rgba(3, 3, 3, 0.355652) 59.17%, #121212 80%), url("../../assets/images/dark.jpg") center 0 no-repeat;
      background-size: contain;
      &--light{
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 36.56%, rgba(255, 255, 255, 0.22) 64.06%, rgba(255, 255, 255, 0.355652) 79.17%, #ffffff 100%), url("../../assets/images/light.jpg") center 0 no-repeat;
      }
    }
  }
}
@media (max-width: 1500px) {
  .steps {
    &-container{
      background: linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0.15) 10.56%, rgba(0, 0, 0, 0.22) 14.06%, rgba(3, 3, 3, 0.355652) 24.17%, #121212 32%), url("../../assets/images/dark.jpg") center 0 no-repeat;
      background-size: contain;
      &--light{
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 36.56%, rgba(255, 255, 255, 0.22) 64.06%, rgba(255, 255, 255, 0.355652) 79.17%, #ffffff 100%), url("../../assets/images/light.jpg") center 0 no-repeat;
      }
    }
  }
}
@media (max-width: 1150px) {
  .steps {
    &-container{
      background: linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0.15) 10.56%, rgba(0, 0, 0, 0.22) 14.06%, rgba(3, 3, 3, 0.355652) 40.17%, #121212 55%), url("../../assets/images/dark.jpg") center 0 no-repeat;
      background-size: contain;
      &--light{
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 36.56%, rgba(255, 255, 255, 0.22) 64.06%, rgba(255, 255, 255, 0.355652) 79.17%, #ffffff 100%), url("../../assets/images/light.jpg") center 0 no-repeat;
      }
    }
  }
}


@media (max-width: 1150px) {
  .steps .step {
    width: 450px;
    margin: 30px 15px;

    &__label {
      font-size: 14px;
    }
  }
}

@media (max-width: 1050px) {
  .steps .step {
    width: 500px;
    margin: 15px;

    &__label {
      font-size: 12px;
    }

    &__box {

      &-interior {
        font-size: 12px;
      }

      &-index {
        font-size: 12px;
      }
    }
  }
  .steps {
    &-container{
      background: linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0.15) 10.56%, rgba(0, 0, 0, 0.22) 14.06%, rgba(3, 3, 3, 0.355652) 32.17%, #121212 37%), url("../../assets/images/dark.jpg") center 0 no-repeat;
      background-size: contain;
      &--light{
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 36.56%, rgba(255, 255, 255, 0.22) 64.06%, rgba(255, 255, 255, 0.355652) 79.17%, #ffffff 100%), url("../../assets/images/light.jpg") center 0 no-repeat;
      }
    }
  }
}

@media (max-width: 992px) {
  .steps {
    &-container{
      background: linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0.15) 10.56%, rgba(0, 0, 0, 0.22) 14.06%, rgba(3, 3, 3, 0.355652) 22.17%, #121212 27%), url("../../assets/images/dark.jpg") center 0 no-repeat;
      background-size: contain;
      &--light{
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 36.56%, rgba(255, 255, 255, 0.22) 64.06%, rgba(255, 255, 255, 0.355652) 79.17%, #ffffff 100%), url("../../assets/images/light.jpg") center 0 no-repeat;
      }
    }
  }
}


@media (max-width: 750px) {
  .steps {
    width: 100%;

    &__title {
      font-size: 22px;
      text-align: left;
      margin-bottom: 30px;
    }
    &-container{
      background: $dark-bg;
      &--light{
        background: white;
      }
    }

    .step {
      flex-direction: column-reverse;
      margin: 0 0 30px;

      &__label {
        background: transparent;
        padding: 0 30px 0 0;
        height: auto;
        font-size: 16px;
        width: 100%;
        line-height: 20px;

        &::after {
          content: none;
        }
      }

      &__box {
        background: transparent;
        padding: 0;
        margin-bottom: 15px;

        &-interior {
          flex-direction: row;
          align-items: center;
          background: transparent;
          padding: 0;
          font-size: 16px;
        }

        &-index {
          padding: 12px 14px;
          margin-bottom: 0;
          margin-right: 10px;
          font-size: 16px;
        }

        &::after {
          content: none;
        }

        &-index {
          text-align: left;
        }
      }
    }
  }

  .steps-container {
    padding: 0 0 40px;

    .steps .step {
      &__box-index {
        background: $main_dark-scheme;
        border-radius: 5px;
      }
    }

    &--light {
      .steps .step {
        &__box-index {
          background: $main_light-scheme;
          color: white;
        }

        &__label {
          color: black;
        }
      }
    }
  }
}
