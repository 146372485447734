@import "../../styles/variables";

.search-select {
  position: relative;
  margin-bottom: 30px;
  background: white;
  border-radius: $border-radius;

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      background: url("../../assets/images/arrowDown.svg")center no-repeat;
      background-size: cover;
      width: 15px;
      height: 10px;
      right: 20px;
      cursor: pointer;
      pointer-events: none;
    }
  }

  &__label {
    position: absolute;
    top: $input-padding;
    left:  $input-padding;
    color: $step-scheme;
    opacity: 0.9;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }

  &__input {
    background: transparent;
    z-index: 1;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: $input-padding - 3px;
    padding-right: 35px;
    border-radius: $border-radius;
    border: 3px solid transparent;

    &--error {
      border: 3px solid $error_scheme;
    }
  }

  &__menu {
    width: 100%;
    position: absolute;
    top: 100%;
    background: white;
    color: black;
    max-height: 100px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &-item {
      padding: 5px 17px;
      cursor: pointer;
    }
  }
}
.select--dark {
  .search-select__input:focus {
    border: 3px solid $focus_input-scheme;
  }
  .search-select__menu-item:hover,
  .search-select__menu-item:focus {
    border-color: $focus_input-scheme;
    background: $focus_input-scheme;
  }
}
.select--light {
  .search-select__input:focus {
    border: 3px solid $main_light-scheme;
  }
  .search-select__menu-item:hover,
  .search-select__menu-item:focus {
    border-color: $main_light-scheme;
    background: $main_light-scheme;
  }
}