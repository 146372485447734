@import "../../styles/variables";

.footer {
  padding: 65px 0 30px 0;
  font-family: $input-font;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 15px;
    &-logo {
      display: flex;
      flex-direction: column;

      .header__logo {
        padding-bottom: 50px;
      }
      .logo-title {
        align-self: start;
        margin-top: 0;
      }
    }
    &-links {
      display: grid;
      grid-template-rows: 1fr 2fr;
      grid-gap: 15px;
    }
    &-names {
      .footer__names-title {
        padding-bottom: 15px;
      }
      ul {
        margin-top: 15px;
      }
    }
  }

  &--light {
    background: $main_light-scheme;
  }

  &--dark {
    background: #121212;
  }

  &__links {
    display: flex;
    font-family: $input-font;

    &-info {

    }
  }

  &__names {
    display: flex;
    justify-content: flex-end;
    font-family: $input-font;

    &-title {
      margin-right: 10px;
    }
  }

  a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-family: $input-font;

    &:hover {
      text-decoration: underline;
    }
  }

  &__list-item {
    margin-bottom: 12px;
    font-family: $input-font;
  }
}

@media (max-width: 1450px) {
  .footer {
    &__container {
      justify-content: space-between;
    }

    &__links-info {
      margin: 0 35px;
    }
  }
}

@media (max-width: 1250px) {
  .footer__links {
    flex-direction: column;
    margin-bottom: 24px;

    &-info {
      margin: 12px 0;
    }
  }
}

@media (max-width: 1024px) {
  .footer__names {
    flex-direction: column;

    &-title {
      margin-bottom: 12px;
    }
  }
}

@media (max-width: 768px) {
  .footer__container {
    grid-template-columns: 1fr;

    &-links {
      grid-template-rows: none;
    }
  }
}
