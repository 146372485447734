@import "../../../styles/variables";

.error-tooltip {
  position: absolute;
  right: $input-padding;
  top: $input-padding;
  cursor: pointer;
  z-index: 10;

  &__description {
    display: inline-block;
    width: 270px;
    position: absolute;
    right: -15px;
    top: 40px;
    z-index: 1000;
    padding: 10px;
    font-size: 0.9rem;
    font-weight: bold;
    background: $error_scheme;
    border-radius: $border-radius;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 80%;
      margin-top: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent $error_scheme $error_scheme transparent;
    }
  }
}

@media (max-width: 540px) {
  .error-tooltip {
    top: -5px;

    &__description {
      &::after {
        border-width: 5px;
      }
    }
  }
}
