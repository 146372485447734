@import "../../../styles/variables";

.select {
  &-container {
    margin-bottom: $margin-bottom-row;
    position: relative;
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background: url("../../../assets/images/arrowDown.svg")center no-repeat;
      background-size: cover;
      width: 15px;
      height: 10px;
      right: 20px;
      cursor: pointer;
      pointer-events: none;
    }

  }
  &__place {
    padding: $input-padding - 3px;
    padding-right: 35px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    right: 0;
    color: $scroll;
    -webkit-appearance: none;
    width: 100%;
    border-radius: $border-radius;
    background-color: white;
    border: 3px solid transparent;

    &:focus {
      border: 3px solid $focus_input-scheme;
    }

    &--error {
      border: 3px solid $error_scheme;
    }
  }
}
